<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Placement/Distribution Details</h1>
    </page-header>

    <page-body>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcPlacementOrDistributionDetails }}</h1>
        <b-row>
          <b-col sm="6" class>
            <div class="d-flex mb-2">
              <i class="mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="29" viewBox="0 0 18 29">
                  <path fill="#003946"
                    d="M19.4133553,126.182446 L13.0210995,139.828758 C12.8020299,140.288119 12.1317422,141.613705 11.6249393,142.617737 C11.5052697,142.852379 11.2647285,143 11.0020622,143 C10.7393958,143 10.4988547,142.852379 10.3791851,142.617737 C9.87238217,141.613705 9.20863387,140.288119 8.99937332,139.828758 L2.58422961,126.182446 C2.36521091,125.704643 2.21229977,125.199041 2.12974186,124.679678 C1.24692392,119.479052 4.97110775,114.485138 10.2058912,114.03562 C12.7204458,113.810842 15.2133359,114.657196 17.0753738,116.367861 C18.9374118,118.078525 19.997056,120.495902 19.9953612,123.029258 C20.0336111,124.110394 19.8349351,125.18678 19.4133553,126.182446 Z M11.7622665,118.580016 C9.83085988,118.246591 7.90655963,119.206127 7.00487413,120.95225 C6.10318862,122.698374 6.43157222,124.829346 7.81672751,126.220592 C9.20188279,127.611838 11.3251216,127.943269 13.0659499,127.039979 C14.8067782,126.136689 15.7646769,124.206501 15.4341352,122.268031 C15.1107327,120.383063 13.6403645,118.906229 11.7622665,118.580016 L11.7622665,118.580016 Z"
                    transform="translate(-2 -114)" />
                </svg>
              </i>
              <h6>{{ plc.loc_name }}</h6>
            </div>
            <div class="address mb-3">
              <span>{{ plc.loc_address_1 }}</span>
              <br />
              <span v-if="!!plc.loc_address_2">{{ plc.loc_address_2 }}<br /></span>
              <span v-if="!!plc.loc_address_3">{{ plc.loc_address_3 }}<br /></span>
              <span>{{ plc.loc_city }}, {{ plc.loc_state }} {{ plc.loc_postal_code }}</span>
              <br />
              <span>{{ plc.loc_country_name }}</span>
            </div>
            <div class="d-flex mb-3">
              <i class="mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <path fill="#B2C3C7"
                    d="M26.6359012,13 C26.3533291,13 26.1242733,13.2238575 26.1242733,13.5 L26.1242733,17.75 C26.1242733,18.02614 26.3533291,18.25 26.6359012,18.25 C26.9184733,18.25 27.1475291,18.02614 27.1475291,17.75 L27.1475291,13.5 C27.1475291,13.2238575 26.9184733,13 26.6359012,13 Z M37.3800872,13 C37.0975151,13 36.8684593,13.2238575 36.8684593,13.5 L36.8684593,17.75 C36.8684593,18.02614 37.0975151,18.25 37.3800872,18.25 C37.6626593,18.25 37.8917151,18.02614 37.8917151,17.75 L37.8917151,13.5 C37.8917151,13.2238575 37.6626593,13 37.3800872,13 Z M23.0545058,14.5 C21.9172081,14.5 21,15.39636 21,16.5078125 L21,21 L43,21 L43,16.5078125 C43,15.39636 42.0827663,14.5 40.9454942,14.5 L39.4186047,14.5 L38.403343,14.5 L38.403343,16.6328125 C38.7171219,16.9074625 38.9149709,17.3059375 38.9149709,17.75 C38.9149709,18.5784275 38.2277805,19.25 37.3800872,19.25 C36.532394,19.25 35.8452035,18.5784275 35.8452035,17.75 C35.8452035,17.3059375 36.0430526,16.9074625 36.3568314,16.6328125 L36.3568314,14.5 L27.6511628,14.5 L27.6511628,16.625 C27.9698251,16.899855 28.1707849,17.30198 28.1707849,17.75 C28.1707849,18.5784275 27.4835944,19.25 26.6359012,19.25 C25.7882079,19.25 25.1010174,18.5784275 25.1010174,17.75 C25.1010174,17.3098975 25.2957328,16.9150175 25.6046512,16.640625 L25.6046512,14.5 L23.0545058,14.5 Z M21,22 L21,32.992175 C21,34.103625 21.9171826,35 23.0545058,35 L40.9454942,35 C42.0827663,35 43,34.103625 43,32.992175 L43,22 L21,22 Z"
                    transform="translate(-21 -13)" />
                </svg>
              </i>
              <p>
                {{ formatedStartDate }}
                <span>|</span>
                {{ plc.mtg_start_time }}
                <span>-</span>
                {{ plc.mtg_end_time }}
              </p>
            </div>
          </b-col>
          <b-col sm="6" class>
            <div class="d-flex mb-2">
              <span class="label font-style-1 mr-2">{{ translations.tcCoordinator }}:</span>
            </div>
            <div class="mb-3">
              <span style="width: 100%">{{ plc.coordinator_name }}</span>
              <br />
              <span style="width: 100%">{{ plc.coordinator_phone }}</span>
              <br />
              <span style="width: 100%">{{ plc.coordinator_email }}</span>
              <br />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" class>
            <div class="d-flex">
              <span class="label font-style-1 mr-2">{{ translations.tcStatus }}:</span>
              <span class="value font-weight-bold">{{ plc.status }}</span>
            </div>
          </b-col>
          <b-col sm="6" class>
            <div class="d-flex">
              <span class="label font-style-1 mr-2">{{ translations.tcMemberType }}:</span>
              <span class="value font-weight-bold">{{ plc.member_participation_type }}</span>
            </div>
          </b-col>
        </b-row>
      </section>

      <section class="section-2 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">{{ translations.tcParticipants }}</h2>
          <ToggleArrow @click="section2 = !section2" section="toggleMe" :status="section2"></ToggleArrow>
        </header>
        <div class="body" v-if="section2">
          <div class="presentation-specs" v-for="(pct, index) in plc.plc_participants" :key="index">
            <div class="font-weight-bold">
              <span class="value">{{ pct.name }}</span>
            </div>
          </div>
          <div class="presentation-specs" v-if="plc.plc_participants.length === 0">
            <div class="font-weight-bold">
              <span class="value">{{ translations.tcNoParticipantsSelected }}</span>
            </div>
          </div>
        </div>
      </section>

      <section class="section-3 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">{{ translations.tcScriptures }}</h2>
          <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
        </header>
        <div class="body" v-if="Section3">
          <div class="presentation-specs" v-for="(prd, index) in plc.plc_products" :key="index">
            <b-row style="margin-bottom: 0px">
              <b-col sm="12" class style="margin-bottom: 10px">
                <div class="font-weight-bold" style="margin-bottom: 10px">
                  <span class="value">{{ prd.product_code_and_name }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" class="pr-0">
                <div class="font-weight-bold mr-6 mb-1">
                  <div class="mr-6 mb-0" sm="6">{{ translations.tcNeeded }}:</div>
                  <div class="mr-6 mb-0 pl-2" sm="6">{{ prd.plc_quantity_needed }}</div>
                </div>
              </b-col>
              <b-col sm="6" class="pr-0 pl-2">
                <div class="font-weight-bold">
                  <div class="mr-6 mb-0" sm="6">{{ translations.tcPlaced }}:</div>
                  <div class="ml-6 mb-0 pl-2" sm="6">{{ prd.plc_quantity_placed }}</div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="presentation-specs" v-if="plc.plc_products.length === 0 && !plc.no_scriptures_required_flag">
            <div class="font-weight-bold">
              <span class="value">{{ translations.tcNoProductsSelected }}</span>
            </div>
          </div>
          <div class="presentation-specs" v-if="plc.no_scriptures_required_flag">
            <div class="font-weight-bold">
              <span class="value">{{ translations.tcNoScripturesRequired }}</span>
            </div>
          </div>
        </div>
      </section>

      <section class="section-4 bg-block mb-4">
        <header class="mb-4">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <p class="pb-3">{{ plc.other_info_note }}</p>
        </div>
      </section>

      <section class="section-5 pt-3 pl-0 pl-sm-5">
        <div class="body">
          <div class="d-flex action-buttons">
            <b-button variant="primary" v-if="iCanSeeAny(secured_placement_edit_controls)"
              @click="handleEditButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0">{{ translations.tcEdit }}
            </b-button>
            <b-button variant="secondary" v-if="iCanSeeAny(secured_placement_delete_controls)"
              @click="handleDeleteButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcDelete
              }}</b-button>
            <b-button variant="tertiary" @click="handleCancelButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd">{{
            translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import date_data from '@/json/date.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'placement-details',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      toggle: true,
      section2: true,
      Section3: true,
      plc: {
        placement_key: null,
        location_key: null,
        loc_name: '',
        loc_adr_key: null,
        loc_address_1: '',
        loc_address_2: '',
        loc_city: '',
        loc_state: '',
        loc_postal_code: '',
        loc_country_name: '',
        loc_country_key: '',
        loc_org_type_name: '',
        coordinator_ind_key: null,
        coordinator_name: '',
        coordinator_phone: '',
        coordinator_email: '',
        member_participation_type: '',
        member_participation_type_key: null,
        members_required: 0,
        number_of_rooms: 0,
        camp_key: null,
        meeting_key: null,
        mtg_start_date: null,
        mtg_end_date: '',
        mtg_start_time: '',
        mtg_end_time: '',
        status: '',
        other_info_note: '',
        plc_participants: [],
        plc_products: [],
        delete_participants: [],
        delete_products: [],
      },
      secured_placement_delete_controls: {
        delete_aux_facility_placement_date_button: 'd715c83b-7099-4e6f-85ad-9f40d515fe98',
        delete_scripture_placement_facility_dates_button: '6fab193a-89e9-4632-ab8c-be1891e047e2',
      },
      secured_placement_edit_controls: {
        edit_aux_facility_placement_date_button: 'f59ba367-3d1a-4335-93ca-c9689ba7a43f',
        edit_scripture_placement_facility_dates_button: '02e0fe88-2a2f-418d-9b12-988deb4bd610',
      },
    }
  },
  methods: {
    ...mapActions({
      clearPlacementDateDetails: 'scriptureDistribution/clearPlacementDateDetails',
      deletePlacementDate: 'scriptureDistribution/deletePlacementDate',
      loadPlacementDateDetails: 'scriptureDistribution/loadPlacementDateDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
    }),
    async handleEditButtonClick() {
      await this.setSelectedLocationKey(this.placementDateDetails.location_key)
      this.$router.push({ name: 'add-placement-date' })
    },
    async handleDeleteButtonClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcPlacementWillBeDeletedOk,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          let payload = {
            mtg_key: this.plc.meeting_key,
            mtg_user: this.userDemograph.member_number,
          }
          this.deleteTheDate(payload)
        }
      })
    },
    async deleteTheDate(payload) {
      let result = null
      await Promise.all([this.setLoadingStatus(true), (result = await this.deletePlacementDate(payload))]).then(
        (result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result[1] === true ? 'success' : 'error',
            text: result[1] === true ? this.translations.tcPlacementDateDeleted : this.translations.tcErrorDuringDelete,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              let linkBack = this.userRouterBackLink
              this.setRouterBackLink('')
              if (linkBack.length > 0) {
                this.$router.push({ name: linkBack })
              } else {
                this.setSelectedLocationKey('')
                this.$router.push({ name: 'placement-dates' })
              }
            }
          })
        }
      )
    },
    async handleCancelButtonClick() {
      await Promise.all([await this.setSelectedPlacementDateKey('')]).then(() => {
        let linkBack = this.userRouterBackLink
        this.setRouterBackLink('')
        if (linkBack.length > 0) {
          this.$router.push({ name: linkBack })
        } else {
          this.setSelectedLocationKey('')
          this.$router.push({ name: 'placement-dates' })
        }
      })
    },
    getTheMonth(curdate) {
      return curdate.toLocaleString(this.prefCulture, { month: 'long' })
    },
    updateDynamicText() {
      if (!this.placementDateDetails || !this.translations.common) return false

      const memberTypeGuid = this.placementDateDetails.member_participation_type_key
      const memberTypeTranslated = this.translations.common['member-types'].hasOwnProperty(memberTypeGuid)
        ? this.translations.common['member-types'][memberTypeGuid]
        : this.placementDateDetails.member_participation_type

      const statusOriginal = this.placementDateDetails.status || '' // `Pending`  `Complete`  `Incomplete`
      const statusTranslated = this.translations.hasOwnProperty(`tc${statusOriginal}`)
        ? this.translations[`tc${statusOriginal}`]
        : statusOriginal

      this.placementDateDetails.member_participation_type = memberTypeTranslated
      this.placementDateDetails.status = statusTranslated
      this.plc = this.placementDateDetails
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      placementDateDetails: 'scriptureDistribution/placementDateDetails',
      prefCulture: 'user/userPreferredCulture',
      userDemograph: 'user/demograph',
      userRouterBackLink: 'user/userRouterBackLink',
      userSelectedPlacementDateKey: 'user/userSelectedPlacementDateKey',
    }),
    months() {
      return date_data.months.map((dm) => dm.text)
    },
    formatedStartDate() {
      let formattedDate = ''
      if (!!this.plc.mtg_start_date) {
        let d = new Date(this.plc.mtg_start_date)
        let month = this.getTheMonth(d)
        let day = d.getDate()
        let year = d.getFullYear()

        formattedDate = `${month} ${day}, ${year}`
      }
      return formattedDate
    },
  },
  async created() {
    if (!this.userSelectedPlacementDateKey) {
      this.$router.push('/programs/sd/scripture-distribution/')
    } else {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.loadPlacementDateDetails(this.userSelectedPlacementDateKey),
          this.getViewTranslations(),
          this.getComponentTranslations('common.member-types'),
        ]).then((results) => {
          this.stripReadableText(results[3])
          const translatedText = { common: { ...this.translations.common } }
          this.$set(this.translations, 'components', translatedText)
          this.updateDynamicText()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    }
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-1 {
  .d-flex {
    align-items: center;
  }

  i {
    width: 22px;
    font-size: 0;
    text-align: center;

    svg {
      display: inline-block;
    }
  }

  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }

  .address {
    padding-left: 46px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.presentation-specs,
.presentation-details {
  max-width: 680px;
  columns: 2;
  column-gap: 40px;
  margin-bottom: 22px;
  padding-bottom: 22px;
  border-bottom: 1px solid #979797;

  @include breakpoint(sm) {
    columns: 1;
    column-gap: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  div {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;

    span {
      display: block;
      color: #636363;
      font-weight: 700;
      line-height: 22px;
    }

    .label {
      display: block;
      margin-right: 0.25rem;
      text-transform: uppercase;
    }
  }
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: middle;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    button {
      min-width: 280px;
    }

    &.action-buttons {
      button {
        min-width: inherit;
      }
    }

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
